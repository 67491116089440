<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import SocketService from "./utils/websocket";
import {
  SerialPortTool,
  Protocol,
  EnteringProductionModeCommand,
  EnteringProductionModeAnswer
} from "@hot_stove/cs_sp_detection";
import { AutoLoginApi } from "@/request/api";
export default {
  methods: {},

  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      AutoLoginApi()
        .then(res => {
          console.log("自动登录1111", res);
          if (token) {
            SocketService.Instance.connect(this);
          }
        })
        .catch(error => {
          console.log("自动登录error", error);
        });
    }
  }
};
</script>

<style lang="less">
html,
body,
#app {
  margin: 0;
  padding: 0;
  // width: 100%;
  // height: 100%;
}

.select-box {
  display: inline-block;
}

.mt-5 {
  margin-top: 5px;
}

/*距上5像素*/
.mt-10 {
  margin-top: 10px;
}

/*距上10像素*/
.mt-15 {
  margin-top: 15px;
}

/*距上15像素*/
.mt-20 {
  margin-top: 20px;
}

/*距上20像素*/
.mt-25 {
  margin-top: 25px;
}

/*距上25像素*/
.mt-30 {
  margin-top: 30px;
}

/*距上30像素*/
.mt-35 {
  margin-top: 35px;
}

/*距上35像素*/
.mt-40 {
  margin-top: 40px;
}

/*距上40像素*/
.mt-50 {
  margin-top: 50px;
}

/*距上50像素*/
.mt-60 {
  margin-top: 60px;
}

/*距上50像素*/
.mt-70 {
  margin-top: 70px;
}

/*距上50像素*/
.mt-80 {
  margin-top: 80px;
}

/*距上50像素*/
.mt-90 {
  margin-top: 90px;
}

/*距上50像素*/
.mt-100 {
  margin-top: 100px;
}

/*距上50像素*/

.mb-5 {
  margin-bottom: 5px;
}

/*距下5像素*/
.mb-10 {
  margin-bottom: 10px;
}

/*距下10像素*/
.mb-15 {
  margin-bottom: 15px;
}

/*距下15像素*/
.mb-20 {
  margin-bottom: 20px;
}

/*距下20像素*/
.mb-25 {
  margin-bottom: 25px;
}

/*距下25像素*/
.mb-30 {
  margin-bottom: 30px;
}

/*距下30像素*/
.mb-35 {
  margin-bottom: 35px;
}

/*距下35像素*/
.mb-40 {
  margin-bottom: 40px;
}

/*距下40像素*/
.mb-50 {
  margin-bottom: 50px;
}

/*距下50像素*/
.mb-60 {
  margin-bottom: 60px;
}

/*距下50像素*/
.mb-70 {
  margin-bottom: 70px;
}

/*距下50像素*/
.mb-80 {
  margin-bottom: 80px;
}

/*距下50像素*/
.mb-90 {
  margin-bottom: 90px;
}

/*距下50像素*/
.mb-100 {
  margin-bottom: 100px;
}

/*距下50像素*/

.ml-5 {
  margin-left: 5px;
}

/*距左5像素*/
.ml-10 {
  margin-left: 10px;
}

/*距左10像素*/
.ml-15 {
  margin-left: 15px;
}

/*距左15像素*/
.ml-20 {
  margin-left: 20px;
}

/*距左20像素*/
.ml-30 {
  margin-left: 30px;
}

/*距左30像素*/
.ml-40 {
  margin-left: 40px;
}

/*距左40像素*/
.ml-50 {
  margin-left: 50px;
}

/*距左50像素*/

.mr-5 {
  margin-right: 5px;
}

/*距右5像素*/
.mr-10 {
  margin-right: 10px;
}

/*距右10像素*/
.mr-15 {
  margin-right: 15px;
}

/*距右15像素*/
.mr-20 {
  margin-right: 20px;
}

/*距右20像素*/
.mr-30 {
  margin-right: 30px;
}

/*距右30像素*/
.mr-40 {
  margin-right: 40px;
}

/*距右40像素*/
.mr-50 {
  margin-right: 50px;
}

/*距右50像素*/

// .duck-green-button {
//   background-color: #009688;
//   color: #fff;
//   border-color: #009688;
// }

// .duck-green-button:hover {
//   color: #fff;
//   background-color: #00a18c;
//   border-color: #00a18c;
// }

// .duck-green-button:active {
//   color: #fff;
//   background-color: #00b8a6;
//   border-color: #00b8a6;
// }

.duck-green-button,
.duck-green-button:focus {
  background-color: #009688;
  color: #fff;
  border-color: #009688;
}

.duck-green-button:hover,
.duck-green-button:active {
  color: #fff;
  background-color: rgba(0, 150, 136, 0.7);
  border-color: #00ac9b;
}

.duck-red-button,
.duck-red-button:focus {
  background-color: #db635f;
  color: #fff;
  border-color: #db635f;
}

.duck-red-button:hover,
.duck-red-button:active {
  color: #fff;
  background-color: rgba(231, 78, 73, 0.7);
  border-color: #db635f;
}

.duck-yellow-button,
.duck-yellow-button:focus {
  background-color: #ffb800;
  color: #fff;
  border-color: #ffb800;
}

.duck-yellow-button:hover,
.duck-yellow-button:active {
  color: #fff;
  background-color: rgba(255, 184, 0, 0.7);
  border-color: #ffb800;
}

.f-l {
  float: left !important;
  _display: inline;
}

.f-r {
  float: right !important;
  _display: inline;
}

.text-l {
  text-align: left;
}

/*水平居左*/
.text-r {
  text-align: right;
}

/*水平居右*/
.text-c {
  text-align: center;
}

/*水平居中*/
.cl:after,
.clearfix:after {
  content: "\20";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.cl,
.clearfix {
  zoom: 1;
}

/*宽度*/
.width-0 {
  width: 0px;
}

.width-10 {
  width: 10px;
}

.width-20 {
  width: 20px;
}

.width-30 {
  width: 30px;
}

.width-40 {
  width: 40px;
}

.width-50 {
  width: 50px;
}

.width-60 {
  width: 60px;
}

.width-70 {
  width: 70px;
}

.width-80 {
  width: 80px;
}

.width-90 {
  width: 90px;
}

.width-100 {
  width: 100px;
}

.width-110 {
  width: 110px;
}

.width-120 {
  width: 120px;
}

.width-130 {
  width: 130px;
}

.width-140 {
  width: 140px;
}

.width-150 {
  width: 150px;
}

.width-160 {
  width: 160px;
}

.width-170 {
  width: 170px;
}

.width-180 {
  width: 180px;
}

.width-190 {
  width: 190px;
}

.width-200 {
  width: 200px;
}

.width-210 {
  width: 210px;
}

.width-220 {
  width: 220px;
}

.width-230 {
  width: 230px;
}

.width-240 {
  width: 240px;
}

.width-250 {
  width: 250px;
}

.width-260 {
  width: 260px;
}

.width-270 {
  width: 270px;
}

.width-280 {
  width: 280px;
}

.width-290 {
  width: 290px;
}

.width-300 {
  width: 300px;
}

.width-310 {
  width: 310px;
}

.width-320 {
  width: 320px;
}

.width-330 {
  width: 330px;
}

.width-340 {
  width: 340px;
}

.width-350 {
  width: 350px;
}

.width-360 {
  width: 360px;
}

.width-370 {
  width: 370px;
}

.width-380 {
  width: 380px;
}

.width-390 {
  width: 390px;
}

.width-400 {
  width: 400px;
}
</style>