import Vue from 'vue'
import Vuex from 'vuex'
import asideCollapse from './asideCollapse'
import menuList from './menuList'
// import webSocket from './webSocket'
// import websocket from './websocket'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    asideCollapse,menuList
  }
})
