import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'reset-css'
import Vue from 'vue'
import App from './App.vue'
import "./fonts/fonts/font.css"
import router from './router'
import store from './store'
// import SocketService from './utils/websocket'
//引入echarts
import '@fortawesome/fontawesome-free/css/all.css'
import * as echarts from 'echarts'
//vue全局注入echarts
Vue.prototype.$echarts = echarts;

// 全局引入上传图片
import EleUploadImage from 'vue-ele-upload-image'
Vue.component(EleUploadImage.name, EleUploadImage)

// 地图
Vue.prototype.$Map = window.TMap

// 对服务器进行websocket的连接
// SocketService.Instance.connect()
// 其他组件的连接
// Vue.prototype.$socket = SocketService.Instance


// Vue.config.errorHandler = function (err, vm, info) {
//   // 处理错误，例如记录日志或显示提示信息
//   console.error('发生错误:', err);
// };

// 禁用webpack-dev-server的overlay插件默认行为
if (module.hot) {
  module.hot.dispose(() => {
    // 清除错误提示
    console.clear();
  });
  module.hot.accept(() => {
    // 重新加载应用
    window.location.reload();
  });
}


Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }

}).$mount('#app')
